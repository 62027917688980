<template>
  <el-row :gutter="20" class="is-flex">
    <el-col :sm="12">
      <div class="group-pie">
        <PieChart
          :data="dataFetch"
          :color="color"
          class="set-width-chart"
          :fetchChart="fetchChart"
        />
        <div class="title-circle">
          <p class="mg-0">{{ sumAll(dataFetch) }}</p>
          <span v-if="typePage == 'project'">โครงการ</span>
          <span v-else>ราย</span>
        </div>
      </div>
    </el-col>
    <el-col :sm="12" class="box-right-pie">
      <div>
        <div
          v-for="(offer, index) in dataFetch"
          :key="index"
          class="mg-b-5 d-flow"
        >
          <div
            class="box-color-name"
            :style="`background:${color[index]}`"
          ></div>

          <el-tooltip
            class="item"
            effect="dark"
            :content="allString(offer)"
            placement="bottom-start"
          >
            <span class="font-18 box-name">
              {{ offer.name }}
              {{ offer.value + " " }}
              <span v-if="typePage == 'project'">โครงการ</span>
              <span v-else>ราย</span>
              <span v-if="offer.value > 0">
                {{ "(" + offer.percent + "%)" }}
              </span>
              <span v-else> (0%) </span>
            </span>
          </el-tooltip>
        </div>
      </div>
      <el-row :gutter="20" v-if="moreDetail">
        <el-col :span="24">
          <p class="font-18" v-if="page == 'offer'">นำเสนอ</p>
          <p class="font-18" v-if="page == 'interested'">สนใจ</p>
          <p class="font-18" v-if="page == 'approve'">
            อนุมัติสินเชื่อ และ ประกัน
          </p>
          <p class="font-24 color-blue-soft">
            {{ dataDetail.offer | comma }} ราย ({{
              totalPercent(
                dataDetail.offer,
                dataDetail.offer + dataDetail.notOffer
              ) + "%"
            }})
          </p>
          <!-- </div> -->
        </el-col>
        <el-col :span="24">
          <p class="font-18" v-if="page == 'offer'">ไม่นำเสนอ</p>
          <p class="font-18" v-if="page == 'interested'">ไม่สนใจ</p>
          <p class="font-18" v-if="page == 'approve'">ไม่อนุมัติสินเชื่อ</p>
          <p class="font-24 color-red-soft">
            {{ dataDetail.notOffer | comma }} ราย ({{
              totalPercent(
                dataDetail.notOffer,
                dataDetail.offer + dataDetail.notOffer
              ) + "%"
            }})
          </p>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>
<script>
import PieChart from "@/charts/PieCharts";
import numeral from "numeral";

export default {
  props: [
    "dataFetch",
    "color",
    "moreDetail",
    "page",
    "dataDetail",
    "fetchChart",
    "typePage",
  ],
  components: {
    PieChart,
  },
  methods: {
    sumAll(data) {
      let total = null;
      data.forEach((e) => {
        total += e.value;
      });
      return numeral(total).format("0,0");
    },
    allString(offer) {
      let result = offer.name + " " + offer.value + " ราย";
      if (offer.value > 0) {
        result += " (" + offer.percent + "%)";
      } else {
        result += " (0%)";
      }
      return result;
    },
  },
};
</script>