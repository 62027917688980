<template>
  <el-row :gutter="20" class="row-custom-dashboard">
    <el-col :span="24" :md="12" class="mg-t-5">
      <div class="bg-white pd-y-5 pd-x-6">
        <el-row :gutter="20" class="row-custom-dashboard in-side">
          <el-col :span="24">
            <h2 class="mg-0">นำเสนอ</h2>
            <BarCharts
              :barWidth="'50px'"
              :label="['Small', 'Medium', 'Large']"
              :height="'300px'"
              :data="dataOffer"
              :color="color"
              :fetchChart="fetchChart"
              v-if="dataOffer.length > 0"
            />
            <div v-else class="nodata">
              <h2 class="mg-0">ไม่มีข้อมูลนำเสนอ</h2>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-col>
    <el-col :span="24" :md="12" class="mg-t-5">
      <div class="bg-white pd-y-5 pd-x-6">
        <el-row :gutter="20" class="row-custom-dashboard in-side">
          <el-col :span="24">
            <h2 class="mg-0">สนใจ</h2>
            <BarCharts
              :barWidth="'50px'"
              :label="['Small', 'Medium', 'Large']"
              :height="'300px'"
              :data="dataInterest"
              :color="color"
              :fetchChart="fetchChart"
              v-if="dataInterest.length > 0"
            />
            <div v-else class="nodata">
              <h2 class="mg-0">ไม่มีข้อมูลที่สนใจ</h2>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-col>
    <el-col :span="24" class="mg-t-5">
      <div class="bg-white pd-y-5 pd-x-6">
        <el-row :gutter="20">
          <el-col :span="24">
            <h2 class="mg-0">อนุมัติ</h2>
            <BarChartsGroup
            :showName="true"
              :data="dataStatusApprove"
              :height="'300px'"
              :color="color"
              :fetchChart="fetchChart"
              v-if="dataStatusApprove.length > 0"
            />
            <h2 v-else class="text-center">ไม่มีข้อมูลอนุมัติ</h2>
          </el-col>
        </el-row>
      </div>
    </el-col>
  </el-row>
</template>
<script>
import BarCharts from "@/charts/BarCharts";
import BarChartsGroup from "@/charts/BarChartsGroup";
import { colorChart } from "@/colors/color.json";

export default {
  props: ["fetchChart", "dataFetch"],
  components: {
    BarChartsGroup,
    BarCharts,
  },
  data() {
    return {
      color: ["#FAC858", "#5470C6", "#FF9900"],
      dataOffer: [],
      dataInterest: [],
      dataStatusApprove: [],
      colorChart: colorChart,
    };
  },
  mounted() {
    this.setData();
  },
  watch: {
    dataFetch() {
      this.setData();
    },
  },
  methods: {
    setData() {
      this.setDataOffers();
      this.setDataInterest();
      this.setDataApprove();
    },
    setDataOffers() {
      this.dataOffer = [];
      const result = Object.values(this.dataFetch.offer).some((e) => e != 0);
      let total = 0;
      Object.values(this.dataFetch.offer).forEach((obj) => {
        total += obj;
      });
      if (result) {
        let setObj = [
          {
            data: Object.values(this.dataFetch.offer),
            name: Object.keys(this.dataFetch.offer),
            total: total,
          },
        ];
        this.dataOffer = setObj;
      }
    },
    setDataInterest() {
      this.dataInterest = [];
      const result = Object.values(this.dataFetch.interest).some((e) => e != 0);
      let total = 0;
      Object.values(this.dataFetch.interest).forEach((obj) => {
        total += obj;
      });
      if (result) {
        let setObj = [
          {
            data: Object.values(this.dataFetch.interest),
            name: Object.keys(this.dataFetch.interest),
            total: total,
          },
        ];
        this.dataInterest = setObj;
      }
    },
    setDataApprove() {
      this.dataStatusApprove = [];
      let values = Object.values(this.dataFetch.approve);
      let arrKey = Object.keys(this.dataFetch.approve);
      let setObj = [];
      let total = 0;
      arrKey.forEach((row, index) => {
        if (
          values[index].approve > 0 ||
          values[index].pending > 0 ||
          values[index].reject > 0
        ) {
          let sizeData = "";
          if (row.toLowerCase() == "s") {
            sizeData = "Small";
          } else if (row.toLowerCase() == "m") {
            sizeData = "Medium";
          } else if (row.toLowerCase() == "l") {
            sizeData = "Large";
          }
          setObj.push({
            product: sizeData,
            อนุมัติ: values[index].approve,
            อนุมัติมีเงื่อนไข: values[index].pending,
            ไม่อนุมัติ: values[index].reject,
          });
        }
        total +=
          values[index].approve + values[index].pending + values[index].reject;
      });
      if (total == 0) {
        this.dataStatusApprove = [];
      } else {
        this.dataStatusApprove = setObj;
      }
    },
  },
};
</script>