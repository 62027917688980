<template>
  <el-row :gutter="20">
    <el-col :span="24">
      <el-row :gutter="20" class="mg-t-5 row-custom-dashboard">
        <el-col :span="24" :md="12" class="mg-t-5">
          <div class="bg-white pd-y-5 pd-x-6">
            <el-row :gutter="20" class="row-custom-dashboard in-side">
              <el-col :span="24">
                <h2 class="mg-0">นำเสนอ</h2>
                <GroupPie
                  :dataFetch="dataOffer"
                  :color="colorChart"
                  :moreDetail="true"
                  :page="`offer`"
                  :fetchChart="fetchChart"
                  :dataDetail="dataOfferDetail"
                  :typePage="`dashboard-all`"
                  v-if="dataOffer.length > 0"
                />
                <div v-else class="nodata">
                  <h2 class="mg-0 text-center">ไม่มีข้อมูลนำเสนอ</h2>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :span="24" :md="12" class="mg-t-5">
          <div class="bg-white pd-y-5 pd-x-6">
            <el-row :gutter="20" class="row-custom-dashboard in-side">
              <el-col :span="24">
                <h2 class="mg-0">สนใจ</h2>
                <GroupPie
                  :dataFetch="dataInterest"
                  :color="colorChart"
                  :moreDetail="true"
                  :dataDetail="dataInterestDetail"
                  :page="`interested`"
                  :fetchChart="fetchChart"
                  :typePage="`dashboard-all`"
                  v-if="dataInterest.length > 0"
                />
                <div v-else class="nodata">
                  <h2 class="mg-0 text-center">ไม่มีข้อมูลสนใจ</h2>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </el-col>

    <el-col :span="24">
      <el-row :gutter="20" class="mg-t-5 row-custom-dashboard">
        <el-col :span="24" :md="12">
          <div class="bg-white pd-y-5 pd-x-6">
            <el-row :gutter="20" class="row-custom-dashboard in-side">
              <el-col :span="24">
                <h2 class="mg-0">อนุมัติ</h2>
                <GroupPie
                  :dataFetch="dataApprove"
                  :color="colorChart"
                  :moreDetail="true"
                  :page="`approve`"
                  :fetchChart="fetchChart"
                  :dataDetail="dataApproveDetail"
                  :typePage="`dashboard-all`"
                  v-if="dataApprove.length > 0"
                />
                <div v-else class="nodata">
                  <h2 class="mg-0 text-center">ไม่มีข้อมูลอนุมัติ</h2>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :span="24" :md="12">
          <div class="bg-white box-1">
            <p v-if="dataFetch.offerAndInterest > 0">
              {{ sumAvg("offer") }} % สนใจ / นำเสนอ
            </p>
            <p v-else style="font-size: 24px; color: #000">
              ไม่มี % สนใจ / นำเสนอ
            </p>
          </div>
          <div class="bg-white box-2">
            <p v-if="dataFetch.approveAndInterest > 0">
              {{ sumAvg("interest") }} % อนุมัติ / สนใจ
            </p>
            <p v-else style="font-size: 24px; color: #000">
              ไม่มี % อนุมัติ / สนใจ
            </p>
          </div>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>
<script>
import PieChart from "@/charts/PieCharts";
import numeral from "numeral";
import GroupPie from "@/components/dashboard/GroupPie";
import { colorChart } from "@/colors/color.json";

export default {
  props: ["fetchChart", "dataFetch"],
  components: {
    PieChart,
    GroupPie,
  },
  mounted() {
    this.setData();
  },
  watch: {
    dataFetch() {
      this.setData();
    },
  },
  data() {
    return {
      colorChart: colorChart,

      color: ["#3598FF", "#FF646A", "#38D85B"],
      dataPie: [
        { value: 3200, name: "สินเชื่อ 3200 ราย (62.3%)" },
        { value: 1700, name: "ประกันการส่งออก 1700 ราย (32.12%)" },
        {
          value: 100,
          name: "สินเชื่อและประกันการส่งออก 100 ราย (1.88%)",
        },
      ],
      dataPie1: [
        { value: 1775, name: "สนใจสินเชื่อ 1775 ราย (52.40%)" },
        { value: 1700, name: "สนใจประกันการส่งออก 1700 ราย (45.55%)" },
        {
          value: 100,
          name: "สนใจสินเชื่อและประกันการส่งออก 100 ราย (2.05%)",
        },
      ],
      dataPie2: [
        { value: 1775, name: "อนุมัติสินเชื่อ 1775 ราย (52.40%)" },
        { value: 1700, name: "อนุมัติประกันการส่งออก 1700 ราย (45.55%)" },
        {
          value: 100,
          name: "อนุมัติสินเชื่อและประกันการส่งออก 100 ราย (2.05%)",
        },
      ],
      dataOffer: [],
      dataOfferDetail: [],
      dataInterest: [],
      dataInterestDetail: [],
      dataApprove: [],
      dataApproveDetail: [],
    };
  },
  methods: {
    setData() {
      this.setDataOffer();
      this.setDataInterest();
      this.setDataApprove();
      // this.setDataLead();
      // this.setDataStatusLead();
      // this.setDataReasonCA();
      // this.setDataReasonLOS();
    },
    sumAvg(type) {
      let sumOffer =
        this.dataFetch.offer.credit +
        this.dataFetch.offer.creditAndInsurance +
        this.dataFetch.offer.insurance;
      let sumInterest =
        this.dataFetch.interest.credit +
        this.dataFetch.interest.creditAndInsurance +
        this.dataFetch.interest.insurance;
         let sumApprove =
        this.dataFetch.approve.credit +
        this.dataFetch.approve.creditAndInsurance +
        this.dataFetch.approve.insurance;
      if (type == "offer" && this.dataFetch.offerAndInterest > 0) {
        let total = (sumInterest * 100) / sumOffer;
        return total.toFixed(2);
      } else if (type == "interest" && this.dataFetch.approveAndInterest > 0) {
        let total = (sumApprove * 100) / sumInterest;
        return total.toFixed(2);
      }
    },
    sumAll(data) {
      let total = null;
      data.forEach((e) => {
        total += e.value;
      });
      return numeral(total).format("0,0");
    },
    setDataOffer() {
      this.dataOffer = [];
      let result = Object.values(this.dataFetch.offer);
      let setResultData = this.dataFetch.offer;
      let total =
        setResultData.credit +
        setResultData.insurance +
        setResultData.creditAndInsurance;
      if (result.some((e) => e != 0)) {
        let setObj = [
          {
            name: "สินเชื่อ",
            value: setResultData.credit,
            percent: this.totalPercent(setResultData.credit, total),
          },
          {
            name: "ประกันการส่งออก",
            value: setResultData.insurance,
            percent: this.totalPercent(setResultData.insurance, total),
          },
          {
            name: "สินเชื่อและประกันการส่งออก",
            value: setResultData.creditAndInsurance,
            percent: this.totalPercent(setResultData.creditAndInsurance, total),
          },
        ];
        this.dataOffer = setObj;
      }
      this.dataOfferDetail = {
        offer: setResultData.offer,
        notOffer: setResultData.notOffer,
      };
    },
    setDataInterest() {
      this.dataInterest = [];
      let result = Object.values(this.dataFetch.interest);
      let setResultData = this.dataFetch.interest;
      let total =
        setResultData.credit +
        setResultData.insurance +
        setResultData.creditAndInsurance;
      if (result.some((e) => e != 0)) {
        let setObj = [
          {
            name: "สินเชื่อ",
            value: setResultData.credit,
            percent: this.totalPercent(setResultData.credit, total),
          },
          {
            name: "ประกันการส่งออก",
            value: setResultData.insurance,
            percent: this.totalPercent(setResultData.insurance, total),
          },
          {
            name: "สินเชื่อและประกันการส่งออก",
            value: setResultData.creditAndInsurance,
            percent: this.totalPercent(setResultData.creditAndInsurance, total),
          },
        ];
        this.dataInterest = setObj;
      }
      this.dataInterestDetail = {
        offer: setResultData.interest,
        notOffer: setResultData.notInterest,
      };
    },
    setDataApprove() {
      this.dataApprove = [];
      let result = Object.values(this.dataFetch.approve);
      let setResultData = this.dataFetch.approve;
      let total =
        setResultData.credit +
        setResultData.insurance +
        setResultData.creditAndInsurance;
      if (result.some((e) => e != 0)) {
        let setObj = [
          {
            name: "สินเชื่อ",
            value: setResultData.credit,
            percent: this.totalPercent(setResultData.credit, total),
          },
          {
            name: "ประกันการส่งออก",
            value: setResultData.insurance,
            percent: this.totalPercent(setResultData.insurance, total),
          },
          {
            name: "สินเชื่อและประกันการส่งออก",
            value: setResultData.creditAndInsurance,
            percent: this.totalPercent(setResultData.creditAndInsurance, total),
          },
        ];
        this.dataApprove = setObj;
      }
      this.dataApproveDetail = {
        offer: setResultData.offer,
        notOffer: setResultData.notOffer,
      };
    },
  },
};
</script>